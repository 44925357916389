import React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"

class SectionHero extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    backTo: PropTypes.string.isRequired,
    renderIcon: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.history = window.history
  }

  render() {
    const { children, backTo, renderIcon: Icon } = this.props

    return <div className="section-hero">
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell">
            <div className="section-back-to-apps">
              <Link className="back-link" to={backTo}>
                <svg className="svg-ico"><use xlinkHref="#icon-back" /></svg>
                Back to Apps
              </Link>
            </div>
          </div>
          <div className="cell section-hero__app-frame">
            <div className="section-hero__app-description">
              <div className="section-hero__app-logo-wrapper">
                <Icon />
              </div>
              <div className="section-hero__app-details">
                <div>
                  {children}
                </div>
              </div>
            </div>
            <div className="section-hero__install-frame">
              <button
                type="button"
                className="cta-button cta-button_huge popupInstall"
              >
                Install
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export { SectionHero }
