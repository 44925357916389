import React from "react"
import PropTypes from "prop-types"

class Details extends React.Component {
  static propTypes = {
    author: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
  }

  render() {
    const { author, version } = this.props
    return <div className="section-app-details__details-panel-wrapper">
      <div className="section-app-details__details-panel">
        <h3>
          App Details
        </h3>
        <div className="section-app-details__details-briefly">
          <div>
            <div className="section-app-details__details-briefly-title">
              Author:
            </div>
            {" "}
            <div className="section-app-details__details-briefly-value">
              {author}
            </div>
          </div>
          <div>
            <div className="section-app-details__details-briefly-title">
              Version:
            </div>
            {" "}
            <div className="section-app-details__details-briefly-value">
              {version}
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export { Details }
