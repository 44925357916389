import React from "react"
import PropTypes from "prop-types"


/**
 * @param {function} renderFullDescription a few paragraphs to describe the app
 */
class Description extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    renderFullDesription: PropTypes.func.isRequired,
    sentence: PropTypes.string.isRequired,
  }

  render() {
    const {
      children,
      renderFullDesription: FullDescription,
      // eslint-disable-next-line no-unused-vars
      sentence,
    } = this.props
    return <div className="section-app-details__complete-description">
      <div className="section-app-details__leading-paragraph">
        {/* {sentence} */}
      </div>
      <div className="section-app-details__full-description">
        <FullDescription />
      </div>
      {children}
    </div>
  }
}

export { Description }
