/* eslint-disable no-restricted-globals */
import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import RehypeReact from "rehype-react"

import Modal from "react-modal"

import "./deskproApp.css"

import {
  appsPath,
  appTabList,
  tabName2path,
  filterGatsbyFileList,
} from "../../misc"

import {
  Carousel,
  Image,
  SEO,
  SwitchCase,
  TabBar,
} from "../../components"

import {
  InnerContainer,
  IntermediateContainer,
  OuterContainer,
} from "../../containerComponents"

import { Details } from "./Details"
import { Description } from "./Description"
import { SectionHero } from "./SectionHero"

const renderAst = new RehypeReact({
  createElement: React.createElement,
}).Compiler


class deskproApp extends React.PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      dpApp: PropTypes.shape({
        dpapp: PropTypes.shape({
          name: PropTypes.string.isRequired,
          url: PropTypes.string,
          title: PropTypes.string.isRequired,
          topics: PropTypes.arrayOf(PropTypes.string).isRequired,
        }).isRequired,
        childrenFile: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          publicURL: PropTypes.string.isRequired,
          childImageSharp: PropTypes.shape({
            // fixed: PropTypes.object.isRequired,
            fluid: PropTypes.object.isRequired,
          }),
        })).isRequired,
        childMarkdownRemark: PropTypes.shape({
          htmlAst: PropTypes.object.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    pageContext: PropTypes.shape({
      tabName: PropTypes.string.isRequired,
    }).isRequired,
  }

  state = {
    modalOpen: false,
    modalSlideIndex: 0,
  }

  constructor(props) {
    super(props)
    const {
      dpapp,
      childrenFile = [],
    } = props.data.dpApp

    // this.dpapp //
    this.dpapp = dpapp

    // this.{defaultIconFile,imageFileList} //
    const getFileList = filterGatsbyFileList(childrenFile);
    [this.defaultIconFile] = getFileList("defaultIcon")
    this.imageFileList = getFileList("image")

    // this.imageMinRatio //
    const sizeList = this.imageFileList
      .filter(({ childImageSharp: cis }) => cis)
      .map(({ childImageSharp: { original } }) => original)
    const ratioList = sizeList.map(({ width, height }) => width / height)
    this.imageMinRatio = Math.min(...ratioList)

    // this.backTo //
    const pathList = dpapp.topics.map(tabName2path).filter((p) => p)
    if (pathList.length !== 1) {
      console.error(
        `Application '${dpapp.name
        }' has [${pathList.length
        }] base tag(s) but it should have exactly 1:`,
        pathList,
        "\nYou should fix the its topics:",
        dpapp.topics,
      )
    }
    [this.backTo] = pathList
  }

  componentDidMount() {
    this.window = window
    console.log({ page: this })
  }

  openModal = (index) => () => {
    this.setState({ modalOpen: true, modalSlideIndex: index })
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  renderImage = (file) => {
    const { title } = this.dpapp
    return <Image
      alt={`${title} screenshot`}
      key={file.publicURL}
      file={file}
    />
  }

  renderThumbnail = (file, index) => {
    const { title } = this.dpapp
    const style = {
      width: "33%",
      padding: "1em",
      display: "inline-block",
      verticalAlign: "top",
    }
    // eslint-disable-next-line
    return <div
      key={file.publicURL}
      style={style}
      onClick={this.openModal(index)}
    >
      <Image
        alt={`${title} screenshot thumnail`}
        file={file}
      />
    </div>
  }

  renderIcon = () => {
    const { title } = this.dpapp
    const { defaultIconFile: file } = this
    return <Image
      alt={`${title} logo`}
      file={file}
    />
  }

  renderReadme = () => {
    const { childMarkdownRemark } = this.props.data.dpApp
    let htmlAst
    if (childMarkdownRemark) {
      ({ htmlAst } = childMarkdownRemark)
    } else {
      htmlAst = { type: "root", children: [] }
    }
    return renderAst(htmlAst)
  }

  renderAbout = () => {
    const {
      author: { name: authorName } = {},
      description,
      version = "",
    } = this.dpapp.packageInfo

    const {
      innerWidth,
      innerHeight,
      document: { body: appElement = null } = {},
    } = this.window || {}

    const minWidth = Math.min(
      innerWidth - 200,
      (innerHeight - 200) * this.imageMinRatio,
    )

    return <div
      className="cell section-app-details__complete-description-wrapper"
      style={{ alignItems: "flex-start" }}
    >
      <Description
        renderFullDesription={() => null}
        sentence={description}
      >
        <div style={{ display: "block" }}>
          {this.imageFileList.map(this.renderThumbnail)}
        </div>
        <this.renderReadme />
      </Description>
      <Details
        author={authorName}
        version={version}
      />

      <Modal
        appElement={appElement} // Hacky but should work
        isOpen={this.state.modalOpen}
        onRequestClose={this.closeModal}
        style={{
          content: {
            width: `${minWidth + 20 + 42 + 42 + 20}px`,
            height: `${minWidth / this.imageMinRatio + 20 + 25 + 10}px`,
            margin: "auto",
            padding: "20px 20px 20px 10px",
          },
          overlay: {
            background: "rgba(0, 0, 0, .75)",
          },
        }}
      >
        <Carousel
          onChange={(modalSlideIndex) => {
            this.setState({ modalSlideIndex })
          }}
          slides={this.imageFileList.map(this.renderImage)}
          value={this.state.modalSlideIndex}
        />
      </Modal>
    </div>
  }

  // renderInstruction = () => {
  //   return null
  // }

  render() {
    const { dpapp } = this
    const { tabName } = this.props.pageContext

    this.page = <OuterContainer className="page-app-detail">
      <SEO title={dpapp.title} keywords={["gatsby", "application", "react"]} />
      <SectionHero
        backTo={this.backTo}
        renderIcon={this.renderIcon}
      >
        <h3>{dpapp.title}</h3>
        <p>
          {dpapp.packageInfo.description}
        </p>
      </SectionHero>
      <IntermediateContainer className="section-app-details ">
        <TabBar
          basePath={appsPath({ name: dpapp.name })}
          activeTab={tabName}
          tabList={appTabList}
        />
        <InnerContainer>
          <div
            className="cell section-app-details__complete-description-wrapper"
            style={{ alignItems: "flex-start" }}
          >
            <SwitchCase
              on={tabName}
              renderObj={{
                about: this.renderAbout,
                instructions: this.renderInstruction,
              }}
            />
          </div>
        </InnerContainer>
      </IntermediateContainer>
    </OuterContainer>

    return this.page
  }
}

export default deskproApp

export const query = graphql`
query($nodeId: String!) {
  dpApp(id: { eq: $nodeId }) {
    dpapp {
      name
      title
      topics
      packageInfo {
        version
        description
        author {
          name
        }
      }
    }
    childMarkdownRemark {
      htmlAst
    }
    childrenFile {
      name
      publicURL
      internal: childLoadNodeContent {
        content
      }
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
          src
        }
        original {
          width
          height
        }
      }
    }
  }
}
`
